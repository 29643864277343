import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import gruponeves from "../../../../../images/enterprises-colorized/gruponeves.svg"

import "./SectionThree.scss"

export default function SectionThree() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  useEffect(() => {
    function pinLabel() {}

    document.addEventListener("scroll", pinLabel)

    pinLabel()
  }, [])
  return (
    <div id="agile-section-three">
      <span id="label-dd">Case de Impacto - Grupo Neves</span>
      <main id="content-dd">
        <h1>
          <img
            src={gruponeves}
            alt="Logo: Grupo Neves"
            className="case-image"
            data-aos="fade-right"
          />
        </h1>
        <div className="case-solutions">
          <div className="case-solution" data-aos="fade-left">
            <h2>Compreensão da "dor" </h2>
            <p>
              Diante do crescimento de suas operações, o{" "}
              <b>Hospital Nossa Senhora das Neves</b> sentiu a necessidade de
              aprimorar seus processos para manter a qualidade dos seus
              serviços.
            </p>
          </div>
          <div
            className="case-solution"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <h2>Solução co-criada</h2>
            <p>
              Customização do Customização do <b> Programa Agile </b> para o
              HNSN com base em sua estrutura e seus objetivos estratégicos para
              formar uma <b>cultura de gestão ágil</b>.
            </p>
          </div>
          <div
            className="case-solution"
            data-aos="fade-left"
            data-aos-delay="150"
          >
            <h2>Execução</h2>
            <p>
              Workshop introdutório sobre metodologias ágeis para toda a
              liderança. Entrega de 18h de capacitação em <b>framework SCRUM</b>{" "}
              e <b>64h de mentoria</b>
              com grupos de projetos.
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}
