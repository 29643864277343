import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import seta from "../../../../../images/mobile/solutions/arrow-desk.svg"
import agile from "../../../../../images/mobile/solutions/corporate/agile.svg"

import "./SectionOne.scss"

const SectionOne = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="agile-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 className="title" data-aos="fade-right">
            AGILE
          </h1>
          <p data-aos="fade-right">
          Implementamos uma cultura ágil na empresa garantindo melhoria no 
          fluxo de comunicação e gestão de projetos e processos da organização.
          </p>
        </div>
        <div className="section-one-image">
          <img src={agile} alt="Missão" data-aos="fade-left" />
          <div className="section-one-member">
            <img src={seta} alt="arrow" className="arrow bounce-infinite" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
